<template>
  <v-container>
    <v-row>
        <v-col cols="12">
            <v-card-title>Stan teczki {{users_in_folder}}/{{max_user_in_folder}}</v-card-title>
        </v-col>
        <v-col cols="12" v-if="step == 1">
            <!-- skanowanie osób -->
            <v-card-title>Skanuj teczkę</v-card-title>
            <div>
                <qrcode-stream @decode="onDecodeFolder"></qrcode-stream>
            </div>
        </v-col>
        <v-col cols="12" v-if="step == 2">
            <!-- skanowanie osób -->
            <v-card-title>Skanuj kod QR uczestnika</v-card-title>
            <div>
                <qrcode-stream @decode="onDecode"></qrcode-stream>
            </div>
        </v-col>
        <v-col cols="12" v-if="step == 3">
            <!-- tabela z danymi -->
            <div>
                <v-col cols="12">
                    <v-card-title>Dane uczestnika</v-card-title>
                    <v-simple-table>
                        <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Paradiso ID</td>
                                <td>{{ userData.paradiso_id }}</td>
                            </tr>
                            <tr>
                                <td><strong>Kwota</strong></td>
                                <td><strong>{{ userData.price }}</strong></td>
                            </tr>
                            <tr>
                                <td>Imię i nazwisko</td>
                                <td>{{ userData.name }} {{ userData.surname }}</td>
                            </tr>
                            <tr>
                                <td>Warsztat</td>
                                <td>{{ userData.workshop }}</td>
                            </tr>
                            <tr>
                                <td>Wiek</td>
                                <td>{{ userData.age }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" v-if="userData.tshirt_version">
                    <v-card-title>Koszulka</v-card-title>
                    <v-simple-table>
                        <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Koszulka</td>
                                <td>{{ userData.tshirt_version }}</td>
                            </tr>
                            <tr>
                                <td>Płeć</td>
                                <td>{{ userData.tshirt_sex }}</td>
                            </tr>
                            <tr>
                                <td>Rozmiar</td>
                                <td>{{ userData.tshirt_size }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" v-if="userData.guardian_id || userData.guardian_name">
                    <v-card-title>Dane opiekuna</v-card-title>
                    <v-simple-table>
                        <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Imię i nazwisko</td>
                                <td>{{ userData.guardian_name }}</td>
                            </tr>
                            <tr>
                                <td>Telefon do opiekuna</td>
                                <td>{{ userData.guardian_phone }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </div>
            <v-col cols="12">
                <v-btn
                @click="accept(userData.id)"
                block
                color="success"
                depressed
                elevation="2"
                >Potwierdź</v-btn>
            </v-col>
            <v-col cols="12">
                <v-btn
                @click="cancel()"
                block
                color="secondary"
                depressed
                elevation="2"
                >Anuluj</v-btn>
            </v-col>
        </v-col>

        <v-col cols="12" v-if="step == 4">
            <!-- juz jest zabookowany -->
            <v-card-title style="color: red;">Uczestnij jest już zabookowany</v-card-title>
            <v-col cols="12">
                <v-btn
                @click="cancel()"
                block
                color="secondary"
                depressed
                elevation="2"
                >OK</v-btn>
            </v-col>
        </v-col>

        <v-col cols="12" v-if="step == 5">
            <!-- bookuj usera -->
            <v-card-title style="color: green;">Bookowanie zostało wykonane poprawnie</v-card-title>
            <v-col cols="12">
                <v-btn
                v-if="users_in_folder >= max_user_in_folder"
                @click="step = 1"
                block
                color="success"
                depressed
                elevation="2"
                >OK</v-btn>

                <v-btn
                v-else
                @click="step = 2"
                block
                color="success"
                depressed
                elevation="2"
                >OK</v-btn>
            </v-col>
        </v-col>

        <v-col cols="12" v-if="step == 6">
            <!-- Przekroczona ilosc zgod w teczce -->
            <v-card-title style="color: red;">Przekroczona ilość zgód w teczce. Max {{max_user_in_folder}} zgód/teczka</v-card-title>
            <v-col cols="12">
                <v-btn
                @click="step = 1"
                block
                color="secondary"
                depressed
                elevation="2"
                >OK</v-btn>
            </v-col>
        </v-col>

        <v-col cols="12" v-if="step == 7">
            <!-- Przekroczona id teczki -->
            <v-card-title style="color: red;">Zły identyfikator teczki. Maksymalny identyfikator to: 999</v-card-title>
            <v-col cols="12">
                <v-btn
                @click="step = 1"
                block
                color="secondary"
                depressed
                elevation="2"
                >OK</v-btn>
            </v-col>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { QrcodeStream } from 'vue-qrcode-reader'
  export default {
    name: 'HelloWorld',
    components: {
      QrcodeStream,
    },
    data: () => ({
        step: 1,
        userData: null,
        folder_id: null,
        users_in_folder: 0,
        max_user_in_folder: 20,
    }),
    methods: {
        async onDecodeFolder(folder_id) {
            console.log(folder_id);
            if (folder_id <= 999) {
                const response = await axios.get(`/api/user/user-in-folder/${folder_id}`);
                console.log(response);
                console.log(response.data.users_in_folder);
                    if (response.data.users_in_folder >= this.max_user_in_folder) {
                        this.step = 6;
                        this.users_in_folder = 0;
                    } else {
                        this.step = 2;
                        this.folder_id = folder_id;
                        this.users_in_folder = response.data.users_in_folder;
                    }
            } else {
                this.step = 7;
            }
        },
        async onDecode(id) {
            console.log(id);
            const response = await axios.get(`/api/user/user-by-paradiso-id/${id}`);
            console.log(response.data);
            if (response.data && response.data.id) {
                this.step = 3;
                this.userData = response.data;
            }
        },
        async accept(id) {
            const response = await axios.post(`/api/user/book/${id}`, {folder_id: this.folder_id});
            console.log(response.data);
            if (response.data) {
                if (response.data.booked == false) {
                    // user juz jest zabookowany
                    this.step = 4;
                    this.users_in_folder = response.data.users_in_folder;
                } else {
                    // zabookuj usera
                    this.step = 5;
                    this.users_in_folder = response.data.users_in_folder;
                }
            }
        },
        cancel() {
            this.step = 2;
            this.userData = null;
        },
    },
  }
</script>
